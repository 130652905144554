import React, { useContext } from "react";
import { Button, Grid, IconButton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AccountContent from "./AccountContent";
import { IAccountProps } from "../../interfaces/account";
import AccountAuthInfo from "./AccountAuthInfo";
import {
  ACCOUNT_PERMISSION,
  ENTITY_ROUTES,
  MERCHANT,
} from "../../utils/constants";
import { AccountCredentialsManagerInfo } from "./AccountCredentialsManagerInfo";
import { UserContext } from "../../contexts/userContext";
import { usePermission } from "../../customHooks/usePermission";

const AccountDesktop = ({
  disableInputs,
  alert,
  values,
  disableSubmit,
  errors,
  handleEdit,
  handleChange,
  handleCancel,
  handleSubmit,
}: IAccountProps) => {
  const {
    currentUser: { entity },
    rules: { contractOwner },
  } = useContext(UserContext);
  const { can_write } = usePermission(ACCOUNT_PERMISSION);
  const navigate = useNavigate();

  const handleBackButton = () => {
    if (disableInputs) navigate(ENTITY_ROUTES[entity]);
    else handleCancel();
  };

  return (
    <Grid container>
      <Grid
        item
        container
        xs={12}
        md={7}
        alignItems="center"
        justifyContent="start"
      >
        <IconButton onClick={handleBackButton}>
          <span className="material-symbols-rounded text-dark">
            keyboard_backspace
          </span>
        </IconButton>
        <Typography className="f-s-20 text-dark" ml={1}>
          {disableInputs ? "Cuenta" : "Datos de cuenta"}
        </Typography>
      </Grid>
      {disableInputs && (
        <Grid
          item
          container
          xs={7}
          justifyContent="space-between"
          alignItems="center"
          className="m-t-30"
        >
          <Typography className="f-s-20 text-dark">Datos de cuenta</Typography>

          {can_write && (
            <Button
              sx={{ "&:hover": { backgroundColor: "transparent" } }}
              size="small"
              variant="text"
              className="f-s-14"
              onClick={handleEdit}
            >
              Editar datos de cuenta
            </Button>
          )}
        </Grid>
      )}
      <AccountContent
        alert={alert}
        errors={errors}
        handleChange={handleChange}
        values={values}
        disableInputs={disableInputs}
        disableSubmit={disableSubmit}
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
      />

      {disableInputs && (
        <>
          {entity !== MERCHANT && <AccountAuthInfo />}
          {contractOwner && (
            <AccountCredentialsManagerInfo contractOwner={contractOwner} />
          )}
        </>
      )}
    </Grid>
  );
};

export default AccountDesktop;
